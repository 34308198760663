 /* custom css */
 /* src/styles.css */
/* @import '~bootstrap/dist/css/bootstrap.min.css';
@import "~@fortawesome/fontawesome-free/css/all.css"; */

@font-face {
    font-family: 'Praktika';
    src: url("../src/assets/fonts/Praktika_Bold.otf") format("opentype");
}

.container-fluid.router-outlet-container {
    width: 90%;
    margin-top: 1%;
    color: #555;
}

a {
    color:  #2BB4D3;
}


.cursorPointer {
  cursor:pointer
}
.cursorNotAllowed{
  cursor:not-allowed !important
}

.fl-color {
    color: #0a1a70 !important;
   }

   [class^="icon-iaqualink-"],
   [class*="icon-iaqualink-"] {
     margin-bottom: -5px;
     width: 20px;
     height: 20px;
     background-image: url("./assets/img/iaqua-sprite.png");
     display:inline-block;
   }

  .icon-iaqualink-add {
    background-position: 0px 0px;
  }
  .icon-iaqualink-check {
    background-position: -24px 0px;
  }
  .icon-iaqualink-edit {
    background-position: 0px -24px;
  }
  .icon-iaqualink-iaqua {
    background-position: -24px -48px;
    display: inline-block !important;
  }
  .icon-iaqualink-info {
    background-position: -47px -48px;
  }
  .icon-iaqualink-location {
    background-position: -24px -24px;
  }
  .icon-iaqualink-settings {
    background-position: -48px 0px;
  }
  .icon-iaqualink-trash {
    background-position: -48px -24px;
  }
  .icon-iaqualink-warning {
    background-position: 0px -48px;
  }
  .icon-iaqualink-close {
    background-position: -72px 0px;
  }